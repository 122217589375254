import React, { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import CancelIcon from "../../../Svg/CancelIcon";
import useOnClickOutside from "@src/hooks/userOutsideHook";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "@app/store/store";
import { saveCity, setCityCenterCoords } from "@app/store/filtersSlice";
import { useGetCityOptionsQuery } from "@app/store/filtersOptionsApi";
import { CustomLoader } from "@shared/ui/CustomLoader";
import { getCityCenterPoint } from "@shared/lib/utils";
import { useDebounceValue } from "@shared/lib/hooks/useDebounceValue";
import { SearchIcon } from "@shared/ui/Icon/ui/Common/SearchIcon";
import { CityOption, LocationFilterProps } from "./types";
import {
    DEBOUNCE_INPUT_TEXT,
    DETECT_BUTTON_TEXT,
    DISTANCE_LABEL_TEXT,
    POPULAR_CITIES,
    SAVE_BUTTON_TEXT,
    SEARCH_INPUT_TEXT
} from "./constants";
import { getCurrentLocation } from "./lib";
import classes from "./LocationFilter.module.scss";

const LocationFilter = ({ onClose, isMultiple = true }: LocationFilterProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const ref = useRef<HTMLDivElement>(null);
    const [inputText, setInputText] = useState("");
    const debouncedInputText = useDebounceValue(inputText, DEBOUNCE_INPUT_TEXT);
    const savedCities = useSelector((state: AppState) => state.filters.city);
    const [selectedCities, setSelectedCities] = useState<string[]>(savedCities);
    const { data: cityOptions, isFetching } = useGetCityOptionsQuery(
        {
            cityName: debouncedInputText
        },
        {
            skip: debouncedInputText.length < 1
        }
    );
    const [data, setData] = useState<CityOption[]>([]);
    const emptyInput = inputText.length === 0;
    const showDataList = (inputText.length > 0 || selectedCities.length === 0) && !isFetching;
    const hasEmptyData = !isFetching && data.length === 0 && inputText === debouncedInputText;

    useOnClickOutside(ref, (event: any) => {
        const isTargetButton = event.composedPath().some((el: HTMLElement) => {
            if (el.getAttribute) {
                const id = el.getAttribute("id");
                return id === "catalog-map-switcher";
            } else {
                return false;
            }
        });
        if (!isTargetButton) {
            onClose();
        }
    });

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };
    const onSelectCity = async (city: string) => {
        setSelectedCities([city]);
        setInputText("");
    };
    const handleDetectLocation = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await getCurrentLocation(setInputText);
    };
    const handleCancelCity = () => {
        setSelectedCities([]);
    };

    const handleSaveCity = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (selectedCities.length > 0 && selectedCities[0]) {
            dispatch(saveCity(selectedCities[0]));
            const center = getCityCenterPoint(selectedCities[0]);
            dispatch(setCityCenterCoords(center));
            localStorage.setItem("cityTimestamp", DateTime.now().toISO());
        } else {
            localStorage.removeItem("city");
            dispatch(saveCity(""));
            dispatch(setCityCenterCoords(null));
            localStorage.removeItem("cityTimestamp");
        }
        onClose();
    };

    useEffect(() => {
        let formattedData = [];

        if (emptyInput) {
            formattedData.push({ id: "label-popular", label: "ПОПУЛЯРНЫЕ", isLabel: true });
            formattedData = formattedData.concat(
                POPULAR_CITIES.map((city, index) => ({
                    id: `popular-${index}`,
                    label: city,
                    isLabel: false
                }))
            );
        } else {
            if (cityOptions) {
                formattedData = cityOptions.map((city: string, index: number) => ({
                    id: index.toString(),
                    label: city,
                    isLabel: false
                }));
            }
        }

        setData(formattedData);
    }, [inputText, cityOptions]);

    return (
        <div
            ref={ref}
            className={`${classes.LocationFilter} ${isMultiple ? classes.Multiple : undefined}`}
        >
            <div className={classes.Header}>
                <div className={classes.SearchInput}>
                    <SearchIcon />
                    <input
                        value={inputText}
                        onChange={onInputChange}
                        placeholder={SEARCH_INPUT_TEXT}
                    />
                </div>
            </div>

            {!inputText && (
                <button className={classes.DetectButton} onClick={handleDetectLocation}>
                    {DETECT_BUTTON_TEXT}
                </button>
            )}
            <div className={classes.Divider}></div>

            <div className={classes.Content}>
                {isFetching && <CustomLoader />}
                {showDataList && (
                    <SimpleBar forceVisible="y" autoHide={false} style={{ maxHeight: 200 }}>
                        <ul
                            style={{
                                borderBottom:
                                    savedCities.length === 0 && emptyInput
                                        ? "0.5px solid #E2E5EB"
                                        : undefined
                            }}
                            className={classes.CityList}
                        >
                            {hasEmptyData && <li>Ничего не найдено</li>}
                            {data.map((el, index) => {
                                const isDivider = emptyInput ? index === 21 || index === 0 : false;

                                return (
                                    <li
                                        key={el.id}
                                        onClick={() => {
                                            if (isDivider) {
                                                return;
                                            }
                                            onSelectCity(el.label);
                                        }}
                                        style={{
                                            display: isDivider
                                                ? emptyInput
                                                    ? "block"
                                                    : "none"
                                                : "flex"
                                        }}
                                        className={isDivider ? classes.UlDivider : undefined}
                                    >
                                        {el.label}
                                    </li>
                                );
                            })}
                        </ul>
                    </SimpleBar>
                )}
                {emptyInput && (
                    <>
                        {selectedCities.length > 0 && (
                            <>
                                <div className={classes.TagsList}>
                                    <div className={classes.Tag}>
                                        {selectedCities[0]}
                                        <CancelIcon
                                            onClick={handleCancelCity}
                                            width={10}
                                            height={10}
                                        />
                                    </div>
                                </div>
                                <div className={classes.Divider}></div>
                            </>
                        )}

                        <div className={classes.Slider}>
                            <label>{DISTANCE_LABEL_TEXT}</label>
                            <Slider
                                handleStyle={{
                                    background: "#3C85F7"
                                }}
                                trackStyle={{
                                    background: "#3C85F7"
                                }}
                                dotStyle={{
                                    background: "#B5BCCB42"
                                }}
                                railStyle={{
                                    background: "#B5BCCB42"
                                }}
                                activeDotStyle={{
                                    borderColor: "#3C85F7",
                                    background: "#3C85F7"
                                }}
                                defaultValue={100}
                                min={0}
                                max={1000}
                                step={null}
                                marks={{
                                    0: 0,
                                    166: "100",
                                    332: 200,
                                    500: 300,
                                    666: 400,
                                    830: 500,
                                    1000: 1000
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className={classes.DividerFull}></div>

            <div className={classes.Footer}>
                <a href="#" onClick={handleSaveCity}>
                    {SAVE_BUTTON_TEXT}
                </a>
            </div>
        </div>
    );
};
export default LocationFilter;