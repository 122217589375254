import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "@app/store/store";
import LocationIcon from "../../../Svg/LocationIcon";
import { LocationButtonProps } from "./types";
import classes from "./LocationButton.module.scss";

const LocationButton = ({ onClick, isConfirmShown }: LocationButtonProps) => {
    const savedCities = useSelector((state: AppState) => state.filters.city);
    let city: string | undefined = savedCities[0];
    if (city === "undefined") {
        city = undefined;
    }

    const [storageCity, setStorageCity] = useState<string | null>(null);
    const labelCity = isConfirmShown ? storageCity : city || "Все города";

    const handleButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
        onClick(e);
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            const localCity = localStorage.getItem("city");
            setStorageCity(localCity);
        }
    }, []);

    return (
        <div onClick={handleButtonClick} className={classes.LocationButton}>
            <LocationIcon />
            <label>{labelCity}</label>
        </div>
    );
};
export default LocationButton;