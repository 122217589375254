export const POPULAR_CITIES = [
    "Москва",
    "Санкт-Петербург",
    "Владивосток",
    "Краснодар",
    "Екатеринбург",
    "Новосибирск",
    "Красноярск",
    "Челябинск",
    "Казань",
    "Воронеж",
    "Ростов-на-Дону",
    "Омск",
    "Тюмень",
    "Нижний Новгород",
    "Уфа",
    "Самара",
    "Иркутск",
    "Волгоград",
    "Пермь",
    "Ярославль"
];
export const DEBOUNCE_INPUT_TEXT = 300;
export const SEARCH_INPUT_TEXT = "Регион, город, населенный пункт";
export const DETECT_BUTTON_TEXT = "Определить автоматически";
export const DISTANCE_LABEL_TEXT = "Расширение радиуса поиска, км";
export const SAVE_BUTTON_TEXT = "Сохранить";