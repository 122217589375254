import { toast } from "react-toastify";
import { SetInputTextFunction } from "./types";
import {
    GEO_ERROR_BROWSER_UNSUPPORTED,
    GEO_ERROR_YANDEX_FETCH,
    GEO_NAVIGATOR_OPTIONS,
    GEO_PERMISSION_WARNING_TEXT,
    GEO_TOAST_ID,
    TOAST_TIME_CLOSE
} from "./constants";

export const getCurrentLocation = async (setInputText: SetInputTextFunction) => {
    if (!navigator.geolocation) {
        toast.error(GEO_ERROR_BROWSER_UNSUPPORTED);
        setInputText("");
        return;
    }

    navigator.geolocation.getCurrentPosition(
        async () => {
            const ymaps = window.ymaps;
            if (!ymaps) {
                setInputText("");
                return;
            }
            ymaps.ready(async () => {
                try {
                    const location = await ymaps.geolocation.get({
                        provider: "yandex",
                        mapStateAutoApply: true
                    });
                    const detectedCity = location.geoObjects.get(0).getLocalities()[0];
                    setInputText(detectedCity);
                } catch (error) {
                    console.error(GEO_ERROR_YANDEX_FETCH, error);
                    setInputText("");
                }
            });
        },
        (error) => {
            if (!toast.isActive(GEO_TOAST_ID)) {
                toast.warn(GEO_PERMISSION_WARNING_TEXT, {
                    toastId: GEO_TOAST_ID,
                    autoClose: TOAST_TIME_CLOSE
                });
            }
            setInputText("");
        },
        GEO_NAVIGATOR_OPTIONS
    );
};